export const useNotifications = () => {
  const notify = (errorMaybe: string | null, successMessage: string | null) => {
    const notificationStore = useNotificationStore();

    if (errorMaybe) {
      notificationStore.notify({
        type: "error",
        message: errorMaybe,
      });
    } else if (successMessage) {
      notificationStore.notify({
        type: "success",
        message: successMessage,
      });
    }
  };

  return {
    notify,
  };
};
